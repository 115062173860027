<template>
    <div class="outer" v-if="!loading">
        <template v-if="user">
            <section class="section-panel">
                <div class="constraint">
                    <div class="full-width">
                        <h1 class="mb-6">Visual Schedule Creator</h1>
                        <v-row class="ma-0 mx-auto max-wrap" v-if="userSchedules.length">
                            <v-col
                                v-if="$vuetify.breakpoint.xsOnly"
                                cols="12"
                                sm="6"
                                lg="3"
                                class="my-4 d-flex align-center justify-center"
                            >
                                <div
                                    class="ma-0 d-flex flex-column align-center justify-center dashed-border"
                                    style="width: 82%; height: 19em"
                                    @click="goTo"
                                >
                                    <v-icon size="60" color="white" class="pb-4">mdi-plus-circle-outline</v-icon>
                                    <div class="icon-text text-center font-weight-bold mt-4">
                                        Create a new<br />
                                        schedule
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                lg="3"
                                class="ma-0 py-2 px-8 d-flex flex-column align-center justify-center"
                                v-for="card in userSchedules"
                                :key="card._id"
                            >
                                <!-- populate cards -->
                                <v-card class="your-card mx-auto pa-3 rounded-lg" width="60vw">
                                    <div class="d-flex justify-space-between">
                                        <v-icon large @click="openEdit(card)"> mdi-file-edit-outline </v-icon>
                                        <v-icon large @click="$refs.deleteDialog.open(card, 'Are you sure you want to delete?')">
                                            mdi-delete
                                        </v-icon>
                                    </div>
                                    <div class="cardImage">
                                        <v-img
                                            :src="card.imgSrc"
                                            width="180"
                                            height="180"
                                            eager
                                            class="ma-auto pt-5 rounded-circle"
                                            @click="openDialog(card)"
                                        />
                                    </div>
                                    <v-card-title class="d-flex justify-center align-center">
                                        <div class="schedule-text font-weight-medium pa-2">
                                            {{ card.title }}
                                        </div>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                            <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" sm="6" lg="3" class="d-flex align-center justify-center">
                                <div
                                    class="ma-0 d-flex flex-column align-center justify-center dashed-border"
                                    style="width: 82%; height: 19em"
                                    @click="goTo"
                                >
                                    <v-icon size="60" color="white" class="pb-4">mdi-plus-circle-outline</v-icon>
                                    <div class="icon-text text-center font-weight-bold mt-4">
                                        Create a new<br />
                                        schedule
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-else class="ma-0 d-flex align-center justify-center">
                            <div class="d-flex align-center justify-center" style="width: 80%; max-width: 15rem">
                                <div
                                    class="ma-0 d-flex flex-column align-center justify-center dashed-border"
                                    style="width: 90%; height: 19em"
                                    @click="goTo"
                                >
                                    <v-icon size="60" color="white" class="pb-4">mdi-plus-circle-outline</v-icon>
                                    <div class="icon-text text-center font-weight-bold mt-4">
                                        Create a new<br />
                                        schedule
                                    </div>
                                </div>
                            </div>
                        </v-row>
                    </div>
                </div>
            </section>
        </template>
        <section class="section-panel">
            <div class="constraint">
                <div class="full-width">
                    <template v-if="user">
                        <h1>Visual Schedules</h1>
                        <p class="intro-text">
                            Beautiful pre-made Visual Schedules. With professional images and a clear layout, perfect for printing or using
                            on your device, anywhere, anytime. Engaging and interactive with clickable images, sound and a finish highlight.
                            New visual schedules are added regularly.
                        </p>
                    </template>
                    <template v-else>
                        <h1>Schedule Library</h1>
                        <p class="intro-text">View our library of pre built schedules</p>
                    </template>
                    <v-row class="mx-auto max-wrap">
                        <v-col
                            cols="12"
                            sm="6"
                            lg="3"
                            class="ma-0 py-2 px-8 d-flex flex-column align-center justify-center"
                            v-for="(card, index) in iconArray"
                            :key="index"
                        >
                            <!-- populate cards -->
                            <v-card elevation="5" class="mx-auto pt-6 rounded-lg" width="60vw" @click="openDialog(card)">
                                <v-img :src="card.imgSrc" width="180" height="180" eager class="ma-auto pt-5" />
                                <v-card-title class="d-flex justify-center align-center">
                                    <div class="schedule-text font-weight-medium pt-4 px-2">{{ card.title }}</div>
                                </v-card-title>
                                <div
                                    v-if="lockCondition(card)"
                                    class="premium-overlay d-flex align-center justify-center p-absolute rounded-lg"
                                >
                                    <v-icon color="white" size="50">mdi-crown</v-icon>
                                </div>
                                <!-- <v-row justify="center"> </v-row> -->
                            </v-card>
                        </v-col>
                    </v-row>
                    <schedule-dialog ref="scheduleDialog"></schedule-dialog>
                    <membership-dialog ref="membershipDialog"></membership-dialog>
                    <delete-dialog ref="deleteDialog" @confirm="deleteItem" :cancelColor="'schedules'"></delete-dialog>
                </div>
            </div>
        </section>
    </div>
    <div class="d-flex full-width full-height flex-grow align-center justify-center" v-else>
        <v-progress-circular indeterminate size="35" color="white"></v-progress-circular>
    </div>
</template>
<script>
import scheduleImage from "@/components/schedule/schedule-images.vue";
import scheduleCard from "@/components/schedule/schedule-card.vue";

import ScheduleDialog from "@/components/schedule/schedule-dialog.vue";
import { mapState } from "vuex";

import membershipDialog from "@/components/schedule/membership-dialog.vue";
import deleteDialog from "@/components/schedule/delete-dialog.vue";

// import test from "@router/routes/schedules/test.vue";

export default {
    components: { scheduleImage, scheduleCard, ScheduleDialog, membershipDialog, deleteDialog },
    data() {
        return {
            cache: 0,
            loading: true,
            schedules: [],
            userSchedules: [],
            active: "Add A Schedule",
            iconArray: [],
            itemArray: [],
            dialog: false,
            hover: false,
            steps: [
                {
                    id: 1,
                    imgId: 0,
                    imgSrc: "",
                },
            ],
            hoverMap: {},
        };
    },
    computed: {
        user() {
            return this.$app?.user;
        },
        ...mapState(["assets"]),
        premiumUser() {
            return this.$app?.user?.isPremium;
        },
        freeTrialUser() {
            return this.$app?.user?.freeTrialUser;
        },
        icons() {
            return [
                {
                    title: "Add A Schedule",
                    icon: "mdi-calendar-plus",
                    html: "Add A<br/>Schedule",
                },
                {
                    title: "Upload A Photo",
                    icon: "mdi-upload",
                    html: "Upload A<br/>Photo",
                },
                {
                    title: "View Image Library",
                    icon: "mdi-image-multiple",
                    html: "View Image<br/>Library",
                },
            ];
        },
        hoverEffectClass() {
            return (cardId) => {
                return this.hoverMap[cardId] ? "" : "hoverEffect";
            };
        },
    },
    methods: {
        lockCondition(item) {
            //Returns TRUE if item should be LOCKED
            return (item?.data?.isPremium && !this.premiumUser) || (item?.data?.isLockedForFreeTrial && this.freeTrialUser);
        },
        setHover(cardId, isHovered) {
            // set hover state for the card
            this.$set(this.hoverMap, cardId, isHovered);
        },
        async init() {
            this.loading = true;
            let schedules = await this.$fluro.api
                //get the basic set of images
                .get("/content/schedule")
                .then(({ data }) => {
                    return data;
                })
                .catch((err) => console.log(err));

            this.schedules = schedules.sort((a, b) => {
                if (a?.data?.isPremium && !b?.data?.isPremium) {
                    return 1;
                } else if (!a?.data?.isPremium && b?.data?.isPremium) {
                    return -1;
                } else {
                    return 0;
                }
            });

            this.iconArray = this.schedules.map((image) => {
                let formattedImage = {
                    ...image,
                    id: image._id,
                    imgSrc: this.getIcon(image),
                    isPremium: image?.data?.isPremium,
                };
                this.iconArray = formattedImage;
                return formattedImage;
            });

            this.userSchedules = [];

            this.userSchedules = await this.$fluro.api
                //get the basic set of images
                .get("/content/scheduleUser", { params: { noCache: true } })
                .then(({ data }) => {
                    console.log("user data", data);
                    return data;
                })
                .catch((err) => console.log(err));

            this.userSchedules = this.userSchedules.map((image) => {
                let formattedImage = {
                    ...image,
                    id: image._id,
                    imgSrc: this.getIcon(image),
                };

                this.userSchedules = formattedImage;
                return formattedImage;
            });

            console.log("line 207 his.userSchedules ", this.userSchedules);

            this.loading = false;
        },
        getIcon(image) {
            if (!image?._id) return;
            return `${this.$fluro.asset.imageUrl(image.data.coverImage._id)}&noRedirect=true`;
        },

        goTo() {
            if (this.premiumUser) {
                this.$router.push({ path: "/create-schedule" });
            } else {
                this.$refs.membershipDialog.open();
            }
        },

        openEdit(item) {
            this.$router.push({
                path: `/edit-schedule/${item._id}`,
            });
        },

        async deleteItem(item) {
            await this.$fluro.api
                .delete(`/content/scheduleUser/${item._id}`)
                .then((resp) => {
                    console.log("deleting schedule", resp);
                })
                .catch((err) => console.log(err));

            console.log("The ITEM:", item);
            this.userSchedules = this.userSchedules.filter((schedule) => {
                return schedule._id != item._id;
            });
            console.warn("User Schedules AFTER DELETE:", this.userSchedules);

            this.$refs.deleteDialog.close();
        },

        openDialog(schedule) {
            if (this.lockCondition(schedule)) {
                this.$refs.membershipDialog.open();
            } else {
                this.$refs.scheduleDialog.open(schedule);
            }
        },
    },

    async mounted() {
        this.$fluro.resetCache();
        await this.init();
    },
    watch: {
        "$vuetify.breakpoint": function (val) {
            console.watch("checking for smaller screen");
            this.hover = val.smAndDown;
        },
    },
};
</script>
<style scoped lang="scss">
.outer {
    background-color: #e0eced;
}

.intro-text {
    font-size: 1.4em;
    margin-bottom: 3em;
}
</style>
<style lang="scss">
$blue: rgb(45, 88, 243);

.drop-zone {
    margin: 5px;
    padding: 5px;
    width: 150px;
    height: 150px;
    border-radius: 50% 50% 50% 50%;
    border: #879ae2 dashed 4px;
    transition: box-shadow 0.3s;
    float: left;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
}

.borders {
    border: #879ae2 !important;
    border-width: 4px !important;
    border-style: dashed !important;
}

.schedule-card {
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    //  box-shadow: none !important;
}

.create-btn {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 0.6em;
    color: white;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 20px;
    padding: 1.2em 2.8em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;

    border-color: $blue;
    color: #fff;
    box-shadow: 0 0 40px 40px $blue inset, 0 0 0 0 $blue;
    transition: all 150ms ease-in-out;

    &:hover {
        box-shadow: 0 0 10px 0 $blue inset, 0 0 10px 4px $blue;
        background-color: #fff;
        color: $blue;
    }
}

@media (min-width: 1181px) {
    .hoverEffect {
        visibility: hidden;
    }
}

.cardImage:hover {
    cursor: pointer;
}

.icons-container-flex {
    column-gap: 15px;
    flex-wrap: wrap;
}

.icon-background {
    background-color: #e8e5e5;
    border-radius: 50%;
    flex: 0 0 auto;
}

.icon-background-active {
    background-color: white;
    border-radius: 50%;
    flex: 0 0 auto;
}

.icon-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.schedule-text {
    text-align: center;
    word-break: keep-all;
    height: 5em;
}

.your-card {
    .schedule-text {
        height: 2.5em;
    }
}

.icon-size {
    width: 6em;
}

.max-wrap {
    max-width: 80vw;
}

@media (min-width: 600px) {
    .icon-size {
        width: 10em;
    }
}

.createScheduleIcon {
    filter: invert(66%) sepia(5%) saturate(3747%) hue-rotate(192deg) brightness(94%) contrast(87%);
}

.premium-overlay {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
</style>
